.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: EEEEEE;
  gap: 1rem;
  margin-top: 75px;
}

.c-title {
  font-size: 2.5vw;
  color: var(--black);
  font-weight: 700;
}

.c-line {
  content: '';
  position: absolute;
  display: block;
  width: 4.5vw;
  height: 0.8vh;
  left: 47.8%;
  margin-top: -5px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #669aff;
  visibility: visible;
}
@media screen and (max-width: 460px) {
  .c-line {
    height: 0.3vh;
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 32vw;
  object-fit: contain;
  padding: 2vw 3.5vw;
  border: none;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-image: url(../../img/Frame.png);
  background-size: cover;
  background-position: center;

  object-fit: contain;
}
.form > h2 {
  color: white;
  font-size: 2vw;
  font-weight: 600;

  letter-spacing: 0.05rem;
}
.form > input {
  justify-content: center;
  padding: 0.9vw;
  display: flex;
  border: none;
  border-radius: 10px;
  font-size: 1.2vw;
}
.form > input ::-webkit-input-placeholder {
  color: black !important;
}

.form > button {
  padding: 0.6vw;
  width: 12vw;
  color: #7aacee;
  font-size: 1.3vw;
  font-weight: 700;
  justify-content: center;
  align-self: center;
  border: none;
  border-radius: 50px;
  background-color: white;
}
.form .message {
  /* position: relative; */
  text-align: flex-start;
  resize: none;
  height: 10rem;
  border: none;
  outline: none;
  font-size: 1.2vw;
  border-radius: 10px;
  display: flex;
  text-transform: capitalize;
  margin-top: -14px;
  font-family: 'Montserrat', sans-serif;
}
@media screen and (max-width: 768px) {
  .form .message {
    height: 4rem;
    border-radius: 5px;
  }
  .form input {
    border-radius: 5px;
  }
  .form {
    gap: 0.7rem;
  }
  .contact-wrapper {
    margin-top: 20px;
  }
}

.form .message::-webkit-input-placeholder {
  padding: 10px;
}
/* .form > input ::placeholder {
  color: black;
} */
