.footer {
  /* margin-top: -190px;*/
  z-index: -1;
  background-image: url(../../img/footer.png);
  height: 72vh;
  background-repeat: no-repeat;
  justify-content: center;
  background-size: cover;
  align-items: center;
  object-fit: contain;
  background-position: center;
}
.f-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.f-content > img {
  width: 4vw;
  height: 8vh;
  margin-top: 250px;
  object-fit: contain;
}
.f-content > h5 {
  font-size: 1.25vw;
  font-weight: 500;
}
.f-content > h6 {
  font-size: 1.25vw;
  font-weight: 500;
  margin-top: -1%;
}
hr {
  width: 80%;
  background-color: #a3caff;
  height: 0.05rem;
  margin-top: -1%;
  border: none;
}
p {
  margin-top: -1%;
  font-size: 0.9vw;
}
@media screen and (max-width: 375px) {
  .f-content > img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .f-content > h5 {
    font-size: 14px;
    font-weight: 500;
  }
  .f-content > h6 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -1%;
  }
  hr {
    width: 80%;
    background-color: #a3caff;
    height: 0.05rem;
    margin-top: -1%;
    border: none;
  }
  p {
    margin-top: -1%;
    font-size: 10px;
  }
}
@media screen and (max-width: 575px) {
  .f-content > img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .f-content > h5 {
    font-size: 14px;
    font-weight: 500;
  }
  .f-content > h6 {
    font-size: 14px;
    font-weight: 500;
    margin-top: -1%;
  }
  hr {
    width: 80%;
    background-color: #a3caff;
    height: 0.05rem;
    margin-top: -1%;
    border: none;
  }
  p {
    margin-top: -1%;
    font-size: 10px;
  }
}
