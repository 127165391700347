.navbar-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 8.5vh;
  padding-left: 9vw;
  padding-right: 9vw;
}
@media screen and (max-width: 400px) {
  .navbar-wrapper {
    height: 5vh;
  }
}
.nav-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  align-items: center;
}
.col1 {
  display: flex;
  flex-direction: row;
  gap: 1.5vw;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.col2 {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
}
.col2 > img {
  width: 7vw;
  height: 7vh;
  display: flex;
  align-items: center;
  object-fit: contain;
}
.col3 {
  display: flex;
  flex-direction: row;
  gap: 1.5vw;
}
.button > button {
  background-color: var(--blue);
  padding: 0.8rem 1.2rem;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 0.9vw;
  font-weight: 700;
  display: flex;
}
.menu {
  display: none;
}
.offCanvasContent {
  display: none;
}
@media screen and (max-width: 768px) {
  .button > button {
    padding: 0.5rem 0.5rem;
    align-items: center;
    justify-self: center;
    justify-content: center;
    margin-top: 5px;
  }
}
.language {
  background-color: transparent;
  border-radius: 50px;
  background-image: url(../../img/united-states.png);
  background-repeat: no-repeat;
  background-size: 1.4vw;
  background-position: 2%;
  border: 1px solid transparent;
  padding: 0.8rem 1.5rem;
  color: var(--black);
  justify-content: center;
  align-items: center;
  display: flex;
}
.language > select {
  background-color: transparent;
  border: none;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  align-items: center;

  font-size: 1vw;
}
.mail :nth-child(1) {
  width: 1.53vw;
  height: 3.5vh;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}
.mail :nth-child(2) {
  justify-content: center;
  align-items: center;
  font-size: 1vw;

  font-weight: 500;
}
.mail {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 0.4rem;
}
.number :nth-child(1) {
  width: 1.53vw;
  height: 3.5vh;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}
.number :nth-child(2) {
  justify-content: center;
  align-items: center;
  font-size: 1vw;

  font-weight: 500;
}
.number {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 0.4rem;
}
@media screen and (max-width: 375px) {
  .navbar-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 8.5vh;
    padding-left: 9vw;
    padding-right: 9vw;
  }
  .nav-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    align-items: center;
  }

  .col2 {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
  }
  .col2 > img {
    width: 18vw;
    height: 18vh;
    display: flex;
    align-items: center;
    object-fit: contain;
  }
  .col3 {
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
  }
  .button > button {
    display: none;
  }
  .language > select {
    background-color: transparent;
    border: none;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 0.7rem;
  }
  .mail :nth-child(1) {
    width: 1vw;
    height: 3.5vh;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .col1 {
    display: none;
    flex-direction: column;
    margin-top: 50vh;
    margin-left: 10vw;
    width: 100vw;
  }
  .mail :nth-child(2) {
    justify-content: center;
    align-items: center;

    font-weight: 500;
  }
  .mail {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 0.4rem;
  }
  .number :nth-child(1) {
    width: 1vw;
    height: 3.5vh;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .number :nth-child(2) {
    justify-content: center;
    align-items: center;
    font-size: 1vw;

    font-weight: 500;
  }
  .number {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 0.4rem;
  }
  .language {
    background-color: transparent;
    border-radius: 50px;
    background-image: url(../../img/united-states.png);
    background-repeat: no-repeat;
    background-size: 5vw;
    background-position: cover;
    border: 1px solid transparent;
    padding: 0rem 1.2rem;
    color: var(--black);
    justify-content: center;
    align-items: center;

    display: flex;
  }
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .menu .circle {
    position: relative;
    border: none;
    background: none;
    z-index: 12;
  }
  .menu .circle > img {
    width: 12vw;
  }

  .offCanvasContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 12;
    margin-top: 125vh;
  }
  .offCanvasContent .col1Off {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: -10px;
    align-items: center;
    position: relative;
    justify-content: center;
  }
  .offCanvasContent .mailOff {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 6vw;
  }

  .offCanvasContent .numberOff {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-left: -35px;
    color: white;
    font-size: 6vw;
  }
  .offCanvasContent .numberOff > :nth-child(1) > img {
    width: 30px;
  }
  .offCanvasContent .mailOff > :nth-child(1) > img {
    width: 30px;
  }

  .offCanvasContent .offButton {
    padding: 11px 11px;
    width: 80vw;

    border: none;
    background-color: white;
    border-radius: 50px;
    color: var(--brand-color-primary-100, #6e8fbb);
    text-align: center;
    margin-top: 200px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.offCanvas {
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  height: 836px;
  overflow-y: hidden;
  overflow-wrap: break-word;
  background: linear-gradient(
    139deg,
    rgba(122, 172, 238, 0.65) 0%,
    rgba(91, 125, 170, 0.65) 100%
  );
  backdrop-filter: blur(15px);
}
@media screen and (max-width: 575px) {
  .navbar-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 8.5vh;
    padding-left: 9vw;
    padding-right: 9vw;
  }
  .nav-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    align-items: center;
  }

  .col2 {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
  }
  .col2 > img {
    width: 15vw;
    height: 17vh;
    display: flex;
    align-items: center;
    object-fit: contain;
  }
  .col3 {
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
  }
  .button > button {
    display: none;
  }
  .language > select {
    background-color: transparent;
    border: none;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 0.7rem;
  }
  .mail :nth-child(1) {
    width: 1vw;
    height: 3.5vh;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .col1 {
    display: none;
    flex-direction: column;
    margin-top: 50vh;
    margin-left: 10vw;
    width: 100vw;
  }
  .mail :nth-child(2) {
    justify-content: center;
    align-items: center;

    font-weight: 500;
  }
  .mail {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 0.4rem;
  }
  .number :nth-child(1) {
    width: 1vw;
    height: 3.5vh;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .number :nth-child(2) {
    justify-content: center;
    align-items: center;
    font-size: 1vw;

    font-weight: 500;
  }
  .number {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 0.4rem;
  }
  .language {
    background-color: transparent;
    border-radius: 50px;
    background-image: url(../../img/united-states.png);
    background-repeat: no-repeat;
    background-size: 3.5vw;
    background-position: cover;
    border: 1px solid transparent;
    padding: 0rem 1.2rem;
    color: var(--black);
    justify-content: center;
    align-items: center;

    display: flex;
  }
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .menu .circle {
    position: relative;
    border: none;
    background: none;
    z-index: 12;
  }
  .menu .circle > img {
    width: 8vw;
  }

  .offCanvasContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 12;
    margin-top: 125vh;
  }
  .offCanvasContent .col1Off {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    align-items: center;
    position: relative;
    justify-content: center;
  }
  .offCanvasContent .mailOff {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 6vw;
  }

  .offCanvasContent .numberOff {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-left: -30px;
    color: white;
    font-size: 6vw;
  }
  .offCanvasContent .numberOff > :nth-child(1) > img {
    width: 30px;
  }
  .offCanvasContent .mailOff > :nth-child(1) > img {
    width: 30px;
  }

  .offCanvasContent .offButton {
    padding: 11px 11px;
    width: 80vw;
    margin-left: 0px;
    border: none;
    background-color: white;
    border-radius: 50px;
    color: var(--brand-color-primary-100, #6e8fbb);
    text-align: center;
    margin-top: 200px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.offCanvas {
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  height: 836px;
  overflow-y: hidden;
  overflow-wrap: break-word;
  background: linear-gradient(
    139deg,
    rgba(122, 172, 238, 0.65) 0%,
    rgba(91, 125, 170, 0.65) 100%
  );
  backdrop-filter: blur(15px);
}
