.cards {
  display: flex;
  flex-direction: column;
  width: 20vw;

  gap: 1rem;
  padding: 2vw;
  background-color: white;
  border: 2px solid #f4f5fd;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  filter: drop-shadow(0px 9px 41px rgba(123, 172, 239, 0.1));
  stroke: #f3f4fe;
  stroke-width: 2vw;
}
.cards > img {
  width: 20vw;
  object-fit: contain;
}
@media screen and (max-width: 375px) {
  .cards {
    width: 85vw;

    height: 38vh;
  }
  .cards img {
    width: 70vw;
  }
  .cards span {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 575px) {
  .cards {
    width: 85vw;

    height: 38vh;
  }
  .cards img {
    width: 70vw;
  }
  .cards span {
    font-size: 0.9rem;
  }
}
