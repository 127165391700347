:root {
  --lightblue: #7c8592;
  --blue: #6e8fbb;
  --gray: #c9c9c9;
  --black: #242424;
  --bg: #7aacee;
}
.App {
  display: flex;
  flex-direction: column;
  overflow-y: none;
}

.back {
  background-image: url(../src/img/Rectangle0.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}
.ellipse {
  background-image: url(../src/img/Ellipse1.png);
  background-repeat: no-repeat;
  background-size: 20vw;
  background-position-x: left;
  object-fit: cover;
}
@media screen and (max-width: 375px) {
  .back {
    background-image: url(../src/img/Rectangle0.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    height: 90vh;
  }
}
@media screen and (max-width: 575px) {
  .back {
    background-image: url(../src/img/Rectangle0.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    height: 90vh;
  }
}
