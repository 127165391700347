.services-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fefefe;
  gap: 2rem;
  margin-top: 50px;
}
.s-title {
  font-size: 2.5vw;
  color: var(--black);
  font-weight: 700;
}
.line {
  content: '';
  position: absolute;
  display: block;
  width: 4.5vw;
  height: 0.8vh;
  left: 48%;
  margin-top: -5px;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #669aff;
  visibility: visible;
}
@media screen and (max-width: 460px) {
  .line {
    height: 0.3vh;
  }
}

.s-row2 {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.s-row3 {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3vw;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 375px) {
  .services-wrapper {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
  }
  .s-row2 {
    display: grid;
    grid-template-columns: 100%;
  }
  .s-row3 {
    display: grid;
    grid-template-columns: 100%;
  }
  .s-title {
    font-size: 6vw;
  }
  .line {
    width: 20vw;
    left: 40%;
    height: 0.9vh;
  }
}
@media screen and (max-width: 575px) {
  .services-wrapper {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
  }
  .s-row2 {
    display: grid;
    grid-template-columns: 100%;
  }
  .s-row3 {
    display: grid;
    grid-template-columns: 100%;
  }
  .s-title {
    font-size: 6vw;
  }
  .line {
    width: 20vw;
    left: 40%;
    height: 0.9vh;
  }
}
