.hero-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../img/Ellipse.png);
  background-repeat: no-repeat;
  background-position-y: 120%;
  background-position-x: right;
  object-fit: cover;
  background-size: 20vw;
  height: 100vh;
}

.hero-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
}
.hero-left {
  align-items: center;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  font-size: 2.4vw;
  text-transform: capitalize;
  font-weight: 700;
}

.desc > h5 {
  margin-top: -10px;
  font-size: 1.2vw;
  font-weight: 400;
  line-height: 1.5;
}
.hero-right :nth-child(1) {
  width: 32.8vw;
  height: 52.9vh;
  object-fit: contain;
}
@media screen and (max-width: 375px) {
  .hero-wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    height: 90vh;
    background-image: url(../../img/Ellipse.png);
    background-repeat: no-repeat;
    background-position-y: 100%;
  }
  .hero-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 0px;
  }
  .hero-left {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;
    flex-direction: column;
  }
  .hero-right {
    display: flex;
    order: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  .hero-right :nth-child(1) {
    width: 200px;
    height: 180px;
    object-fit: contain;
    margin-top: 0px;
  }
  .title {
    font-size: 16px;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
  }
  .desc > h5 {
    font-size: 13px;
    width: 100%;
    margin-left: 0%;
    align-items: center;
    justify-content: center;

    text-align: center;
  }
}
@media screen and (max-width: 868px) {
  .hero-wrapper {
    height: 70vh;
  }
}
@media screen and (max-width: 575px) {
  .hero-wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    height: 100vh;
    background-image: url(../../img/Ellipse.png);
    background-repeat: no-repeat;
    background-position-y: 75%;
  }
  .hero-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 0px;
  }
  .hero-left {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;
    flex-direction: column;
  }
  .hero-right {
    display: flex;
    order: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  .hero-right :nth-child(1) {
    width: 200px;
    height: 180px;
    object-fit: contain;
    margin-top: 0px;
  }
  .title {
    font-size: 16px;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
  }
  .desc > h5 {
    font-size: 13px;
    width: 100%;
    margin-left: 0%;
    align-items: center;
    justify-content: center;

    text-align: center;
  }
}
